
import { Component, Vue } from 'vue-property-decorator'
import { formatRecentOrders, getRecentOrders } from '@/helpers/OrderSessionHelpers'
import { environment } from '@/helpers/Environment'
import type { RecentOrder } from '@/routes/user/my-account/types'

@Component({})
export default class RecentOrders extends Vue {
  loading: boolean = false
  orders: Dict<RecentOrder> | null = null

  // TODO: Remove these config options once we have confidence in this in production
  enabled: boolean = environment.config.my_account_pages?.recent_orders ?? false

  created() {
    this.loading = true
    getRecentOrders()
      .then((data) => {
        if (!data) return
        const { orderSessions, venues, templates } = data
        this.orders = formatRecentOrders(orderSessions, venues, templates)
      })
      .finally(() => (this.loading = false))
  }
}
