






import { Component, Prop, Vue } from 'vue-property-decorator'
import { navigateBack } from '@/helpers/route'

@Component({
  name: 'NavigateBack',
})
export default class extends Vue {
  @Prop({ required: false })
  content: string

  private navigateBackOne() {
    navigateBack(this.$router)
  }
}
