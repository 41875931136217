import { API } from '@/api/API'
import { groupBy, indexBy, indexItemsById } from '@/helpers/IndexHelpers'
import type { RecentOrder, SessionRow, TicketRow } from '@/routes/user/my-account/types'
import { TixTime } from '@/TixTime/TixTime'

function formatDate(datetime, timezone) {
  const d = new TixTime(datetime, timezone)
  return d.format('ddd, MMMM D YYYY')
}

function formatTime(datetime, timezone) {
  const d = new TixTime(datetime, timezone)
  return d.format('h:mm A')
}

function sortByStartDate(a, b) {
  a = new TixTime(a.start_datetime)
  b = new TixTime(b.start_datetime)
  return a.isSameOrBefore(b, 'seconds') ? -1 : 1
}

function sortByPurchaseDate(a, b) {
  a = new TixTime(a.purchased_on)
  b = new TixTime(b.purchased_on)
  return a.isSameOrBefore(b, 'seconds') ? 1 : -1
}

function getUpcomingSessions(sessions: Array<SearchResult>) {
  const now = new TixTime()
  return sessions.filter((session) => new TixTime(session.start_datetime).isAfter(now)).sort(sortByStartDate)
}

export function getOrderSessions(): Promise<Array<SearchResult>> {
  return API.getUncached<'search_result'>(`my/order_session`).then((response) => {
    // Filter out gift cards, donations, memberships, admission passes; any sessions without a start time
    return response.search_result._data.filter((session) => session.start_datetime)
  })
}

export function getOrderTotalPrice(id: string): Promise<number> {
  // Don't use the "my" prefix for this call
  // "my/ticket_order" doesn't include "ticket_order_fees" in the response
  return API.getUncached<'ticket_order' | 'ticket_order_fees'>(`ticket_order/${id}`).then((response) => {
    return parseFloat(response.ticket_order_fees._data[0].total_after_fees_price)
  })
}

interface OrderData {
  templates: Array<EventTemplate>
  orderSessions: Array<SearchResult>
  venues: Array<Venue>
  price?: number
}

export function getOrderDetail(id): Promise<OrderData | null> {
  return getRecentOrders(id).then((result) => {
    if (!result) return null
    return getOrderTotalPrice(id).then((price) => {
      return { ...result, price } as OrderData
    })
  })
}

export function getRecentOrders(id?): Promise<OrderData | null> {
  return getOrderSessions().then((orderSessions) => {
    if (!orderSessions.length) return null
    if (id) orderSessions = groupBy('ticket_order_id', orderSessions)[id]

    const eventIds = orderSessions.map((order) => order.event_template_id)
    return API.getCached<'event_template' | 'venue'>('event_template', {
      query: { 'id._in': eventIds.join(',') },
      embed: 'venue',
    }).then((response) => {
      return {
        orderSessions: orderSessions.sort(sortByPurchaseDate),
        venues: response.venue._data,
        templates: response.event_template._data,
      }
    })
  })
}

export function formatRecentOrders(
  orders: Array<SearchResult>,
  venues: Array<Venue>,
  templates: Array<EventTemplate>,
  price?: number,
): Dict<RecentOrder> {
  const venuesById = indexItemsById(venues)
  const templatesById = indexItemsById(templates)

  const result = {}
  orders.forEach((order) => {
    const id = order.ticket_order_id
    const template = templatesById[order.event_template_id]
    const timezone = template ? venuesById[template.venue_id].timezone : null
    if (result[id]) {
      result[id].numTickets += order.num_tickets
      result[id].numSessions += 1
    } else {
      result[id] = {
        orderNumber: order.order_number,
        purchaseDate: formatDate(order.purchased_on, timezone),
        numTickets: order.num_tickets,
        numSessions: 1,
        ticketOrderId: id,
        totalPrice: price ?? null,
      }
    }
  })
  return result
}

export function getMyUpcomingSessions(): Promise<Array<SessionRow> | null> {
  return getOrderSessions().then((orderSessions) => {
    const upcoming = getUpcomingSessions(orderSessions)
    return upcoming.length > 0 ? getSessionRows(upcoming) : null
  })
}

export function getMyUpcomingSession(id): Promise<SessionRow> {
  return getOrderSessions()
    .then((orderSessions) => {
      return getSessionRows(groupBy('event_session_id', orderSessions)[id])
    })
    .then((rows) => rows[0])
}

export function getSessionRows(orderSessions: Array<SearchResult>): Promise<Array<SessionRow>> {
  const eventIds = orderSessions.map((session) => session.event_template_id)

  return API.getCached<'event_template' | 'venue' | 'meta'>('event_template', {
    query: { 'id._in': eventIds.join(',') },
    embed: 'meta,venue',
  }).then((response) => {
    const profileImages = indexBy(
      'resource_id',
      response.meta._data.filter((metaItem) => {
        return metaItem.metakey === 'image_profile' && metaItem.resource === 'event_template'
      }),
    )
    const venues = indexItemsById(response.venue._data)
    const templates = indexItemsById(response.event_template._data)

    const result: Dict<SessionRow> = {}
    for (const session of orderSessions) {
      const sessionId = session.event_session_id
      if (result[sessionId]) {
        result[sessionId].ticketQuantity += session.num_tickets
        result[sessionId].orderIds.push(session.ticket_order_id)
      } else {
        const template = templates[session.event_template_id]
        const timezone = venues[template.venue_id].timezone
        const now = new TixTime()

        result[sessionId] = {
          name: session.event_name,
          id: session.event_session_id,
          date: formatDate(session.start_datetime, timezone),
          time: formatTime(session.start_datetime, timezone),
          ticketQuantity: session.num_tickets,
          image: profileImages[session.event_template_id]?.value,
          orderIds: [session.ticket_order_id],
          isUpcoming: new TixTime(session.start_datetime, timezone).isAfter(now),
        }
      }
    }
    return Object.values(result)
  })
}

interface TicketOrderResponse {
  tickets: Array<TicketRow>
  orders: Array<TicketOrder>
}

export function getTicketOrders(session: SessionRow): Promise<TicketOrderResponse> {
  return API.getUncached<'ticket_order' | 'ticket_group' | 'ticket_type' | 'ticket'>(`my/ticket_order`, {
    query: { 'id._in': session.orderIds.join(',') },
    embed: 'ticket,ticket_type,ticket_group',
  }).then((response) => {
    const groups = indexItemsById(response.ticket_group._data)
    const types = indexItemsById(response.ticket_type._data)
    const tickets = groupBy('ticket_order_id', response.ticket._data)

    const result: TicketOrderResponse = {
      tickets: [],
      orders: [],
    }
    response.ticket_order._data.forEach((order) => {
      const sessionTickets: Array<TicketRow> = tickets[order.id]
        .filter((ticket) => ticket.event_session_id === session.id)
        .map((ticket) => ({
          group: groups[ticket.ticket_group_id].name,
          type: types[ticket.ticket_type_id].name,
          code: ticket.scan_code,
        }))
      result.tickets = result.tickets.concat(sessionTickets)
      result.orders = result.orders.concat(order)
    })
    return result
  })
}
