import { auth0LoginPath } from '@/helpers/Auth0Client'
import { environmentName } from '@/helpers/Environment'
import { areAnonymousOrderEditsEnabled } from '@/helpers/SelfEdit'
import CancelAutoRenewRoute from '@/routes/CancelAutoRenewRoute.vue'
import CheckoutRoute from '@/routes/CheckoutRoute.vue'
import CompletedOrdersRoute from '@/routes/CompletedOrdersRoute.vue'
import EventDetailRoute from '@/routes/EventDetailRoute.vue'
import EventListingRoute from '@/routes/EventListingRoute.vue'
import HomeRoute from '@/routes/HomeRoute.vue'
import CancelFreeOrderRoute from '@/routes/manage-order/CancelFreeOrderRoute.vue'
import EditOrderRoute from '@/routes/manage-order/EditOrderRoute.vue'
import RescheduleRoute from '@/routes/manage-order/RescheduleRoute.vue'
import ResendOrderRoute from '@/routes/manage-order/ResendOrderRoute.vue'
import NotFoundRoute from '@/routes/NotFoundRoute.vue'
import PendingOrdersRoute from '@/routes/PendingOrdersRoute.vue'
import RedeemMembershipRoute from '@/routes/RedeemMembershipRoute.vue'
import Auth0LoginRoute from '@/routes/user/Auth0LoginRoute.vue'
import ForgotMembership from '@/routes/user/ForgotMembership.vue'
import ForgotPasswordRoute from '@/routes/user/ForgotPasswordRoute.vue'
import MembershipRoute from '@/routes/user/MembershipRoute.vue'
import MembershipDetails from '@/routes/user/my-account/MembershipDetails.vue'
import MyAccount from '@/routes/user/my-account/MyAccount.vue'
import MyProfile from '@/routes/user/my-account/MyProfile.vue'
import OrderDetail from '@/routes/user/my-account/OrderDetail.vue'
import RecentOrders from '@/routes/user/my-account/RecentOrders.vue'
import SessionDetail from '@/routes/user/my-account/SessionDetail.vue'
import UpcomingSessions from '@/routes/user/my-account/UpcomingSessions.vue'
import PasswordResetRoute from '@/routes/user/PasswordResetRoute.vue'
import SetEmailAddressRoute from '@/routes/user/SetEmailAddressRoute.vue'
import storyViewerRoute from '@/stories/route'
import type { RouteConfig } from 'vue-router'
import TransferCartRoute from '@/routes/TransferCartRoute.vue'

// TODO Import all/most routes asynchronously?
// @see https://v3.router.vuejs.org/guide/advanced/lazy-loading.html#grouping-components-in-the-same-chunk

const homeRoute: RouteConfig = {
  path: '/',
  name: 'home',
  component: HomeRoute,
}

const eventListingRoute: RouteConfig = {
  path: '/events',
  name: 'events',
  component: EventListingRoute,
}

const eventDetailRoutes: RouteConfig[] = [
  {
    path: '/events/:id/:action',
    name: 'event/action',
    component: EventDetailRoute,
    props: true,
  },
  {
    path: '/events/:id',
    name: 'event',
    component: EventDetailRoute,
    props: true,
  },
]

const transferCartRoute: RouteConfig = {
  path: '/transfer-cart/:cartId',
  name: 'cart',
  component: TransferCartRoute,
}

const checkoutRoute: RouteConfig = {
  path: '/checkout/:cartId?',
  name: 'checkout',
  component: CheckoutRoute,
}

const completedOrdersRoute: RouteConfig = {
  path: '/checkout/complete',
  name: 'checkout/complete',
  component: CompletedOrdersRoute,
}

const pendingOrdersRoute: RouteConfig = {
  path: '/checkout/pending/:cartId',
  name: 'checkout/pending',
  component: PendingOrdersRoute,
}

const membershipRoute: RouteConfig = {
  path: '/membership/:action?',
  name: 'membership',
  component: MembershipRoute,
  props: true,
}

const myAccountRoute: RouteConfig = {
  path: '/my-account',
  name: 'my-account',
  component: MyAccount,
  children: [
    {
      path: 'membership-details',
      component: MembershipDetails,
      name: 'membership-details',
    },
    {
      path: 'my-profile',
      component: MyProfile,
      name: 'my-profile',
    },
    {
      path: 'upcoming',
      component: UpcomingSessions,
      name: 'upcoming-sessions',
    },
    {
      path: 'upcoming/session/:id',
      component: SessionDetail,
      props: true,
      name: 'session-detail',
    },
    {
      path: 'orders',
      component: RecentOrders,
      name: 'recent-orders',
    },
    {
      path: 'orders/:id',
      component: OrderDetail,
      props: true,
      name: 'order-detail',
    },
  ],
}

const redeemMembershipRoute: RouteConfig = {
  path: '/membership/redeem',
  name: 'membership/redeem',
  component: RedeemMembershipRoute,
  props: true,
}

const userRoutes: RouteConfig[] = [
  {
    path: '/user/forgot-membership',
    name: 'user/forgot-membership',
    component: ForgotMembership,
  },
  {
    path: '/user/forgot-password',
    name: 'user/forgot-password',
    component: ForgotPasswordRoute,
  },
  {
    path: '/user/reset-password',
    name: 'user/reset-password',
    component: PasswordResetRoute,
  },
  {
    path: '/user/set-email-address',
    name: 'user/set-email-address',
    component: SetEmailAddressRoute,
  },
]

const resendRoute: RouteConfig = {
  path: '/resend',
  name: 'resend',
  component: ResendOrderRoute,
}

const editOrderRoutes: RouteConfig[] = [
  {
    path: '/manage/:ticketOrderId/cancel-auto-renew',
    name: 'cancel-auto-renew',
    component: CancelAutoRenewRoute,
    props: true,
  },
]

if (areAnonymousOrderEditsEnabled()) {
  editOrderRoutes.push(
    {
      path: '/manage/:id',
      name: 'manage',
      component: EditOrderRoute,
      props: true,
    },
    {
      path: '/manage/:orderId/reschedule/:sessionId',
      name: 'reschedule',
      component: RescheduleRoute,
      props: true,
    },
    {
      name: 'manage/checkout',
      path: '/manage/:orderId/confirm',
      component: CheckoutRoute,
    },
  )
} else {
  editOrderRoutes.push({
    path: '/manage/:id',
    name: 'manage',
    component: CancelFreeOrderRoute,
    props: true,
  })
}

const errorRoutes: RouteConfig[] = [
  {
    path: '*',
    name: 'error/404',
    component: NotFoundRoute,
  },
]

const auth0LoginRoute: RouteConfig = {
  path: auth0LoginPath,
  component: Auth0LoginRoute,
}

// IMPORTANT: The order is important.
// If complete (/checkout/complete) does not precede checkout (/checkout/:id?),
// then /checkout route handles /checkout/complete too.
const routes: RouteConfig[] = [
  ...errorRoutes,
  completedOrdersRoute,
  pendingOrdersRoute,
  transferCartRoute,
  checkoutRoute,
  ...userRoutes,
  auth0LoginRoute,
  ...eventDetailRoutes,
  eventListingRoute,
  redeemMembershipRoute,
  myAccountRoute,
  membershipRoute,
  resendRoute,
  ...editOrderRoutes,
  homeRoute,
]

if (environmentName !== 'prod') {
  routes.push(storyViewerRoute)
}

export default routes
