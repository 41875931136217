
import { Component, Vue } from 'vue-property-decorator'
import { TixTime } from '@/TixTime/TixTime'
import store from '@/store/store'
import type { TTStringFormat } from '@/TixTime/helpers'
import { isCurrentOrPastMember, membershipIsRenewable, membershipRenewableFrom } from '@/state/Membership'
import { mapGetters } from 'vuex'
import { API } from '@/api/API'
import { firstEntity } from '@/api/Helpers'
import { isLoggedIn } from '@/state/Authentication'
import { environment } from '@/helpers/Environment'
import type { EventWithConfig, LinkedTG } from '@/api/types/processedEntities'
import { getMembershipConfigProperty } from '@/helpers/Config'

@Component({
  computed: mapGetters({
    membership: 'Member/membership',
    membershipLevelName: 'Member/membershipLevelName',
    membershipId: 'Member/membershipId',
    membershipScanCode: 'Member/membershipScanCode',
    autoRenewal: 'Member/autoRenewal',
    ticketGroupWithConfig: 'Member/ticketGroupWithConfig',
    eventTemplateWithConfig: 'Member/eventTemplateWithConfig',
  }),
})
export default class MembershipDetails extends Vue {
  membershipId: string
  membershipScanCode: string
  membership: undefined | { auto_renew_state: AutoRenewState; ticket_order_id: string }
  membershipLevelName: string
  autoRenewal: AutoRenewState
  savedCard: SavedPaymentMethod | null = null
  ticketGroupWithConfig: LinkedTG
  eventTemplateWithConfig: EventWithConfig
  showEmailSentMessage: boolean = false
  loading: boolean = false

  created() {
    if (this.isLoggedIn) {
      API.getUncached('my/identity/saved_payment_method').then((resp) => {
        const savedCard = firstEntity(resp, 'saved_payment_method')?.[0]
        this.savedCard = savedCard
      })
    }
  }

  get isLoggedIn(): boolean {
    return isLoggedIn()
  }

  get renewAllowed() {
    return membershipIsRenewable()
  }

  get expiry(): string {
    const expiry: TixTime = store.getters['Member/membershipExpiry']
    const medium = this.$t('dateFormat.medium') as TTStringFormat
    const formattedExpiry = expiry.format(medium)
    const renewFrom = membershipRenewableFrom()?.format(medium)

    if (!this.renewAllowed && renewFrom) {
      return `${formattedExpiry} (renew from ${renewFrom})`
    } else {
      return formattedExpiry
    }
  }

  get cardIcon() {
    if (!this.savedCard) {
      return null
    }
    const identifier = this.savedCard.identifier
    const brand = identifier.toLowerCase().split('…')[0].replace(/\s/g, '')
    return `/assets/default/images/${brand}.svg`
  }

  get cancelAutoRenewRoute() {
    const id = this.membership?.ticket_order_id
    return `/manage/${id}/cancel-auto-renew`
  }

  get autoRenewEnabled() {
    const legacy = environment.config.ask_to_autorenew ? 'prompt' : undefined
    const ticketGroup = this.ticketGroupWithConfig
    const event = this.eventTemplateWithConfig
    const autorenew = getMembershipConfigProperty('renewal', 'automatic', ticketGroup, event, legacy) ?? null
    return autorenew === 'prompt'
  }

  resendMembershipEmail() {
    this.loading = true
    API.post('my/ticket_order/resend', { body: { memberships_only: true } })
      .then(() => {
        this.showEmailSentMessage = true
      })
      .finally(() => {
        this.loading = false
      })
  }

  get hasMembership(): boolean {
    return isCurrentOrPastMember()
  }
}
