
import { Component, Prop, Vue } from 'vue-property-decorator'
import NavigateBack from '@/components/elements/NavigateBack.vue'
import { formatRecentOrders, getOrderDetail, getSessionRows } from '@/helpers/OrderSessionHelpers'
import EventsList from '@/routes/user/my-account/EventsList.vue'
import type { RecentOrder, SessionRow } from '@/routes/user/my-account/types'
import Price from '@/components/elements/Price.vue'
import { API } from '@/api/API'
import { environment } from '@/helpers/Environment'

@Component({
  methods: {
    environment() {
      return environment
    },
  },
  components: { EventsList, NavigateBack, Price },
})
export default class OrderDetail extends Vue {
  @Prop({ required: true })
  id: string

  // TODO: Remove these config options once we have confidence in this in production
  enabled: boolean = environment.config.my_account_pages?.recent_orders ?? false

  loading: boolean = false
  showEmailSentMessage: boolean = false
  order: RecentOrder | null = null
  sessions: Array<SessionRow> | null = null

  created() {
    this.loading = true
    getOrderDetail(this.id)
      .then((result) => {
        if (!result) return
        const { orderSessions, venues, templates, price } = result
        this.order = formatRecentOrders(orderSessions, venues, templates, price)[this.id]
        return getSessionRows(orderSessions).then((response) => {
          this.sessions = response
        })
      })
      .finally(() => (this.loading = false))
  }

  get showResendLink() {
    return this.sessions!.some((session) => session.isUpcoming)
  }

  resendTickets() {
    this.loading = true
    API.post(`ticket_order/${this.id}/email`, { body: {} })
      .then(() => {
        this.showEmailSentMessage = true
      })
      .finally(() => {
        this.loading = false
      })
  }
}
