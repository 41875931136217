import type { MembershipRules } from '@/api/Membership'
import type { ReserveTicketPayload } from '@/api/types/payloads'
import type { EventDetails } from '@/api/types/processedEntities'
import { cartParams } from '@/helpers/BuyMembershipHelpers'
import { getMembershipConfigProperty } from '@/helpers/Config'
import { mapEntries } from '@/helpers/DictHelpers'
import { environment, timezone } from '@/helpers/Environment'
import { groupBy, indexBy } from '@/helpers/IndexHelpers'
import { reserveTickets } from '@/state/Reserve'
import type { UntimedItem } from '@/store/CartItem'
import store from '@/store/store'
import { TixTime } from '@/TixTime/TixTime'

export interface MembershipSummary {
  benefits: MemberBenefits
  validFrom: TixTime
  validTo: TixTime
}

export interface MembershipWithTimeObjects extends MembershipEntity {
  benefits: MemberBenefits
  validTo: TixTime
  validFrom: TixTime
}

export function currentMembership(): MembershipSummary | void {
  const now = new TixTime(undefined, timezone)
  return membershipOnDate(now)
}

export function membershipOnDate(date: TixTime): MembershipSummary | void {
  const memberships = allMemberships()
  return findMembershipOnDate(memberships, date)
}

export function purchasedMembershipOnDate(date: TixTime): MembershipSummary | void {
  const memberships = purchasedMemberships()
  return findMembershipOnDate(memberships, date)
}

function findMembershipOnDate(memberships, date) {
  return memberships.find((membership) => {
    const from = membership.validFrom
    const to = membership.validTo
    return date.isBetween(from, to, 'day', '[]')
  })
}

export function inCartMembership(): MembershipSummary | void {
  const gifting = store.getters['Cart/giftee']
  const items = store.getters['Cart/cartItems']
  const item = items.find((item) => item.isMembershipEvent)

  if (item && !gifting) {
    return cartItemToMembership(item)
  }
}

export function purchasedMemberships(): MembershipSummary[] {
  const memberships = store.getters['Member/memberships'] as MembershipWithTimeObjects[]
  return memberships.map((membership) => ({
    validTo: membership.validTo,
    validFrom: membership.validFrom,
    benefits: membership.member_event_purchase_limit,
  }))
}

export function hasCurrentPurchasedMembership(): boolean {
  const now = new TixTime(undefined, timezone)
  return purchasedMembershipOnDate(now) ? true : false
}

export function isCurrentMember(): boolean {
  return currentMembership() ? true : false
}

export function isCurrentOrPastMember(): boolean {
  const memberships = purchasedMemberships()
  return memberships.length > 0
}

function allMemberships(): MembershipSummary[] {
  const inCart = inCartMembership()
  // Always prioritise an in-cart membership over any purchased memberships so that the customer can not unnecessarily
  // purchase a membership in order to get benefits that they already have.
  return inCart ? [inCart] : purchasedMemberships()
}

function cartItemToMembership(cartItem: UntimedItem): MembershipSummary | void {
  const rules = membershipRules()

  if (rules) {
    const limits: MemberBenefits = {}

    for (const lineItem of cartItem.types) {
      // cartItem can contain ticket types where type.group.handler === 'codes' which are not relevant to member benefits.
      if (lineItem.group.handler === 'membership') {
        const name = rules[lineItem.group.id][lineItem.type.id].member_event_ticket_type
        if (limits[name]) {
          limits[name] += lineItem.ticketCount
        } else {
          limits[name] = lineItem.ticketCount
        }
      }
    }

    return {
      benefits: limits,
      validTo: cartItem.validTo,
      validFrom: cartItem.validFrom,
    }
  }
}

function membershipRules(): Dict<Dict<MembershipRuleEntity>> | void {
  const rules = store.state['membershipRules'] as MembershipRuleEntity[]
  if (rules.length) {
    const byGroup = groupBy('ticket_group_id', rules)
    return mapEntries(byGroup, (group) => indexBy('ticket_type_id', group))
  }
}

export function addMembershipToCart(
  event: EventDetails,
  membershipRules: MembershipRules,
  selectedLevel: string,
  promoCodes: Array<string | null | undefined>,
  tickets: ReserveTicketPayload[],
  surveyAnswers: Dict<Primitive>,
  autoRenew: boolean,
  codes?: string[],
): Promise<void> {
  store.commit('Cart/autoRenewMembership', autoRenew)
  // Ensure the membershipRules are available to create a membership entity from an in-cart membership.
  // @see cartItemToMembership()
  store.commit('membershipRules', membershipRules.rules)
  const changes = cartParams(event, selectedLevel, surveyAnswers, promoCodes.filter((code) => code != null) as string[])
  changes.add = tickets
  if (codes) {
    changes.preReservePromoCodes = { codes }
  }
  return reserveTickets(changes)
}

export function allowMemberReserveBeyondTerm(): boolean {
  const reserveBeyondTerm = environment.config.member_reserve_session_beyond_term
  const autoRenew = store.state['Cart'].autoRenewMembership
  return reserveBeyondTerm === 'always' || (reserveBeyondTerm === 'if_autorenew' && autoRenew)
}

export function membershipIsRenewable(): boolean {
  const allowedDate = membershipRenewableFrom()
  if (allowedDate) {
    return allowedDate.isBefore(new TixTime(null, timezone))
  } else {
    return true
  }
}

export function membershipRenewableFrom(): TixTime | undefined {
  const config = membershipExpiryConfig()
  const expiry: TixTime | undefined = store.getters['Member/membershipExpiry']
  if (expiry !== undefined && config !== null) {
    return expiry.subtract(config, 'days')
  }
}

function membershipExpiryConfig(): number | null {
  const ticketGroup = store.getters['Member/ticketGroupWithConfig']
  const event = store.getters['Member/eventTemplateWithConfig']
  return getMembershipConfigProperty('renewal', 'allowed_period', ticketGroup, event) ?? null
}
