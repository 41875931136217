
import { Component, Vue, Watch } from 'vue-property-decorator'
import FormInput from '@/components/forms/FormInput2.vue'
import FormInput2 from '@/components/forms/FormInput2.vue'
import { getIdentityFormFields } from '@/helpers/IdentityFormHelpers'
import { getIdentityPayload, IdentityFormData } from '@/helpers/IdentityHelpers'
import { mapGetters } from 'vuex'
import IdentityForm from '@/components/forms/IdentityForm.vue'
import store from '@/store/store'
import { splitFullName } from '@/helpers/StringHelpers'
import type { WithMeta } from '@/api/types/processedEntities'
import { environment } from '@/helpers/Environment'

@Component({
  components: { IdentityForm, FormInput2, FormInput },
  computed: mapGetters({
    identityFormConfigurations: 'Cart/identityFormConfigurations',
    identityWithMeta: 'Member/identityWithMeta',
  }),
})
export default class MyProfile extends Vue {
  identityFormConfigurations: string[]
  identityFormData: IdentityFormData | null = null
  identityWithMeta: Array<WithMeta<Identity>>
  loading: boolean = false

  // TODO: Remove these config options once we have confidence in this in production
  enabled: boolean = environment.config.my_account_pages?.my_profile ?? false

  @Watch('identityWithMeta', { immediate: true })
  updateIdentityFormData() {
    const identity = this.identityWithMeta[0]
    const [first, last] = splitFullName(identity.name)
    this.identityFormData = {
      ...identity.meta,
      first_name: first,
      last_name: last,
      email: identity.email,
    }
  }

  get identityFormFields() {
    return getIdentityFormFields(this.identityFormData!, this.identityFormConfigurations, [])
  }

  updateIdentity() {
    this.loading = true
    const payload = getIdentityPayload(this.identityFormData!)

    store.dispatch('Member/updateUser', payload).finally(() => {
      this.loading = false
    })
  }
}
