
import { Component, Prop, Vue } from 'vue-property-decorator'
import { environment } from '@/helpers/Environment'
import type { SessionRow } from '@/routes/user/my-account/types'

@Component({})
export default class EventsList extends Vue {
  @Prop({ required: true })
  sessions: Array<SessionRow>

  // TODO: Remove these config options once we have confidence in this in production
  sessionsEnabled: boolean = environment.config.my_account_pages?.upcoming_events ?? false
}
