
import { Component, Vue } from 'vue-property-decorator'
import EventsList from '@/routes/user/my-account/EventsList.vue'
import type { SessionRow } from '@/routes/user/my-account/types'
import { getMyUpcomingSessions } from '@/helpers/OrderSessionHelpers'
import { environment } from '@/helpers/Environment'

@Component({
  components: { EventsList },
})
export default class UpcomingSessions extends Vue {
  loading: boolean = false
  upcoming: Array<SessionRow> | null = null

  // TODO: Remove these config options once we have confidence in this in production
  enabled: boolean = environment.config.my_account_pages?.upcoming_events ?? false

  created() {
    this.loading = true
    getMyUpcomingSessions()
      .then((upcoming) => (this.upcoming = upcoming))
      .finally(() => (this.loading = false))
  }
}
