
import { Component, Prop, Vue } from 'vue-property-decorator'
import NavigateBack from '@/components/elements/NavigateBack.vue'
import { getMyUpcomingSession, getTicketOrders } from '@/helpers/OrderSessionHelpers'
import { API } from '@/api/API'
import { environment } from '@/helpers/Environment'
import type { SessionRow, TicketRow } from '@/routes/user/my-account/types'

@Component({
  components: { NavigateBack },
})
export default class SessionDetail extends Vue {
  @Prop({ required: true })
  id: string

  // TODO: Remove these config options once we have confidence in this in production
  enabled: boolean = environment.config.my_account_pages?.upcoming_events ?? false
  recentOrdersEnabled: boolean = environment.config.my_account_pages?.recent_orders ?? false

  orders: Array<TicketOrder> | null = null
  tickets: Array<TicketRow> | null = null
  session: SessionRow | null = null
  loading: boolean = false
  showEmailSentMessage: boolean = false

  created() {
    this.loading = true

    getMyUpcomingSession(this.id)
      .then((session) => {
        this.session = session
        return session
      })
      .then(getTicketOrders)
      .then((response) => {
        this.orders = response.orders
        this.tickets = response.tickets
      })
      .finally(() => {
        this.loading = false
      })
  }
  resendTickets() {
    this.loading = true
    this.orders!.forEach((order) => {
      API.post(`ticket_order/${order.id}/email`, { body: {} })
        .then(() => {
          this.showEmailSentMessage = true
        })
        .finally(() => {
          this.loading = false
        })
    })
  }

  get viewOrderLinks(): Array<{ label: string; path: string }> {
    return this.orders!.map((order) => {
      return {
        label: this.orders!.length === 1 ? 'View order' : `View order ${order.order_number}`,
        path: `/my-account/orders/${order.id}`,
      }
    })
  }
}
