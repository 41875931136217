






import CalendarIcon from '@/components/icons/CalendarIcon.vue'
import CartIcon from '@/components/icons/CartIcon.vue'
import CheckmarkIcon from '@/components/icons/CheckmarkIcon.vue'
import ChevronDownIcon from '@/components/icons/ChevronDownIcon.vue'
import ChevronLeftIcon from '@/components/icons/ChevronLeftIcon.vue'
import ChevronRightIcon from '@/components/icons/ChevronRightIcon.vue'
import ClockIcon from '@/components/icons/ClockIcon.vue'
import DocumentIcon from '@/components/icons/DocumentIcon.vue'
import ExclamationIcon from '@/components/icons/ExclamationIcon.vue'
import GiftAidIcon from '@/components/icons/GiftAidIcon.vue'
import InfoIcon from '@/components/icons/InfoIcon.vue'
import LogoutIcon from '@/components/icons/LogoutIcon.vue'
import MinusIcon from '@/components/icons/MinusIcon.vue'
import PencilIcon from '@/components/icons/PencilIcon.vue'
import PlusIcon from '@/components/icons/PlusIcon.vue'
import StarIcon from '@/components/icons/StarIcon.vue'
import TimesIcon from '@/components/icons/TimesIcon.vue'
import TrashIcon from '@/components/icons/TrashIcon.vue'
import UserIcon from '@/components/icons/UserIcon.vue'
import TagIcon from '@/components/icons/TagIcon.vue'
import { SVGIconName } from '@/types/ConfigYaml'
import { Component, Prop, Vue } from 'vue-property-decorator'
import UserCircleIcon from '@/components/icons/UserCircleIcon.vue'
import { configYml } from '@/helpers/Environment'
import CircleIcon from '@/components/icons/CircleIcon.vue'
import EyeCloseIcon from '@/components/icons/EyeCloseIcon.vue'
import EyeIcon from '@/components/icons/EyeIcon.vue'
import DashIcon from '@/components/icons/DashIcon.vue'
import TriangleIcon from '@/components/icons/TriangleIcon.vue'
import TicketIcon from '@/components/icons/TicketIcon.vue'
import ClockRotateLeftIcon from '@/components/icons/ClockRotateLeftIcon.vue'

@Component({
  name: 'SvgIcon',
  components: {
    CartIcon,
    TrashIcon,
    ChevronDownIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
    PlusIcon,
    MinusIcon,
    CalendarIcon,
    TimesIcon,
    UserIcon,
    UserCircleIcon,
    DocumentIcon,
    LogoutIcon,
    PencilIcon,
    CheckmarkIcon,
    InfoIcon,
    ExclamationIcon,
    GiftAidIcon,
    ClockIcon,
    StarIcon,
    TagIcon,
    CircleIcon,
    EyeCloseIcon,
    EyeIcon,
    DashIcon,
    TriangleIcon,
    TicketIcon,
    ClockRotateLeftIcon,
  },
})
export default class extends Vue {
  @Prop({ required: true })
  name: SVGIconName

  @Prop()
  title: string

  get overriddenTemplate(): string | false {
    return configYml?.svgIconOverrides?.[this.name] ?? false
  }
}
