
import { Component, Vue } from 'vue-property-decorator'
import TixMessage from '@/components/elements/TixMessage.vue'
import Loader from '@/components/Loader.vue'
import NotFoundError from '@/errors/NotFoundError'
import CartAlreadyBookedError from '@/errors/CartAlreadyBookedError'
import store from '@/store/store'
import type { Route } from 'vue-router'
import { logInTrackJS } from '@/errors/helpers'
import { environment } from '@/helpers/Environment'

@Component({ name: 'TransferCartRoute', components: { Loader, TixMessage } })
export default class TransferCartRoute extends Vue {
  error: string | null = null

  beforeRouteEnter(to: Route, from: Route, next: Function) {
    // TODO Support transferring carts that are assigned to the logged in user with GET my/cart/:id.
    // @see fetchOwnCart()
    store
      .dispatch('Cart/inject', to.params.cartId)
      .then(() => {
        if (store.getters['Cart/cart'].ownedByPublicIdentity) {
          // The guest cart is unassigned.
          next(to.query.next ?? '/checkout')
        } else {
          // If a member's cart is transferred and the user is not logged in, the cart loaded successfully,
          // but is not be modifiable. This is not currently supported
          // TODO Support it but without the ?next= parameter, just like /checkout/:id?
          logInTrackJS('TransferCartRoute: Cart not owned by public identity', {
            cartId: to.params.cartId,
            cartIdentityId: store.getters['Cart/cart'].identity_id,
            publicIdentityId: environment.portal.public_identity_id,
          })
          store.dispatch('Cart/clearAll')
          next((vm: TransferCartRoute) => (vm.error = 'This cart belongs to another member'))
        }
      })
      .catch((error) => {
        if (error.response?.status === 403) {
          logInTrackJS('TransferCartRoute: 403 Access denied to cart', error)
          next((vm: TransferCartRoute) => (vm.error = '403 Access denied to cart'))
        } else if (error instanceof NotFoundError || error.response?.status === 404) {
          logInTrackJS('TransferCartRoute: 404 Cart not found', error)
          next((vm: TransferCartRoute) => (vm.error = '404 Cart not found'))
        } else if (error instanceof CartAlreadyBookedError) {
          next(`/checkout/complete?orderIds=${error.orderId}`)
        } else {
          throw error
        }
      })
  }
}
