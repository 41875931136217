
import AutocompleteInput from '@/components/forms/AutocompleteInput.vue'
import FormInput2 from '@/components/forms/FormInput2.vue'
import {
  addressSearch,
  AddressSearchResult,
  getAddressAutocompleteApiKey,
  getAddressData,
  retrieveAddress,
} from '@/helpers/AddressHelpers'
import type { AutocompletedAddressData } from '@/helpers/IdentityHelpers'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { logInTrackJS } from '@/errors/helpers'
import type { FormInputField } from '@/components/forms/types'
import { LanguageStrings } from '@/language/types'

@Component({
  name: 'AddressInput',
  components: { AutocompleteInput, FormInput2 },
})
export default class extends Vue {
  @Prop() value: AutocompletedAddressData | null
  @Prop() country: string | null
  @Prop() inputMode: 'autocomplete' | 'manual'
  @Prop() field: FormInputField
  @Prop() required: boolean | null

  t: LanguageStrings['addressInput']

  errorInternal: string | null = null

  addresses: AddressSearchResult[] | null = null
  selectedAddress: AutocompletedAddressData | null = null
  minSearchLength = 4
  timeout: ReturnType<typeof setTimeout>

  searchInput: string = ''
  focused = false

  created() {
    if (this.value?.address) {
      this.searchInput = this.value.address
    }
  }

  get autocompleteEnabled(): boolean {
    return Boolean(this.apiKey && this.country && this.inputMode === 'autocomplete')
  }

  get input() {
    return this.value?.address ?? ''
  }

  set input(value: string) {
    this.$emit('input', { address: value })
  }

  get intermediateInput() {
    return this.searchInput
  }

  set intermediateInput(value: string) {
    this.searchInput = value

    // Handle autofill and manual entry.
    if (!this.focused || !this.autocompleteEnabled) {
      this.selectedAddress = { address: value }
    }
  }

  // Triggered on both new address suggestions and focusing the input.
  @Watch('addresses')
  scrollTo() {
    // TODO Is there a better way of doing this?
    if (this.autocompleteEnabled) {
      this.$nextTick(() => {
        this.$el.scrollIntoView()
      })
    }
  }

  get apiKey() {
    return getAddressAutocompleteApiKey()
  }

  @Watch('searchInput')
  onSearchInputChange(value: string) {
    if (this.timeout) {
      clearTimeout(this.timeout)
    }

    if (!this.shouldSearch(value)) {
      this.addresses = null
      return
    }

    this.errorInternal = null
    this.selectedAddress = null

    if (this.country) {
      const country = this.country
      this.timeout = setTimeout(() => this.lookupAddresses(country, value), 300)
    } else {
      this.addresses = null
    }
  }

  shouldSearch(input: string): boolean {
    return (
      this.autocompleteEnabled &&
      input.length >= this.minSearchLength &&
      (!this.selectedAddress || input !== this.selectedAddress?.address) &&
      this.focused
    )
  }

  lookupAddresses(country: string, input: string | AddressSearchResult) {
    const params = typeof input === 'string' ? { Text: input } : { Container: input.Id }
    addressSearch(country, params)
      .then((items) => {
        this.addresses = items
      })
      .catch(this.handleError)
  }

  retrieveAddress(address: AddressSearchResult) {
    retrieveAddress(address.Id)
      .then((items) => {
        this.selectedAddress = getAddressData(items[0])
        this.validate()
      })
      .catch(this.handleError)
  }

  @Watch('selectedAddress')
  onSelectedAddress() {
    this.$emit('input', this.selectedAddress ?? {})

    if (this.selectedAddress) {
      this.searchInput = this.selectedAddress.address
    }
  }

  // Loqate error format https://www.loqate.com/developers/api/generic-errors/
  handleError(error: { Error: string; Description: string; Cause: string; Resolution: string } | any) {
    // Revert to manual address entry on error.
    this.switchToManualMode()
    logInTrackJS(error.Description ?? error, error)
  }

  switchToManualMode() {
    this.input = this.searchInput
    this.$emit('manualModeClick')
  }

  @Watch('inputMode')
  onInputModeChange() {
    if (this.value) {
      this.errorInternal = this.error
    }
    this.onSearchInputChange(this.input)
  }

  addressSelected(address: AddressSearchResult | null) {
    if (address?.Type === 'Container') {
      this.lookupAddresses(this.country!, address)
    } else if (address?.Type === 'Address') {
      this.retrieveAddress(address)
    } else {
      this.selectedAddress = null
    }
  }

  @Watch('disabled')
  validate() {
    const input = this.inputEl
    this.errorInternal = this.error
    this.$emit('validation', this.error, input)
  }

  get error() {
    if (this.autocompleteEnabled && !this.selectedAddress) {
      return this.t.errorMessage
    } else {
      return null
    }
  }

  get inputEl() {
    return this.$el.querySelector('input, select') as HTMLInputElement
  }

  get eventListeners() {
    return {
      ...this.$listeners,
      // Maintain compatibility with v-model.
      // @see https://vuejs.org/v2/guide/components-custom-events.html#Binding-Native-Events-to-Components
      input: () => null,
    }
  }

  get placeholder(): string | undefined {
    if (this.autocompleteEnabled) {
      return this.t.placeholder
    } else if (this.field.showPlaceholderDropdownOption) {
      return this.field.placeholderLanguage
    } else {
      return undefined
    }
  }

  setFocused(value: boolean) {
    this.focused = value
  }
}
