
import { Component, Prop, Vue } from 'vue-property-decorator'

export type NavigationRoute = {
  route: string | (() => void)
  label: string
  icon: string
}

@Component({})
export default class DropdownNavigation extends Vue {
  @Prop({ required: true })
  routes: Array<NavigationRoute>

  @Prop()
  additionalProps: any

  get currentRoute(): NavigationRoute | null {
    return (
      this.routes.find((route) => {
        return typeof route.route === 'string' && this.$route.path.includes(route.route)
      }) || null
    )
  }

  onSelect(navigationItem) {
    if (typeof navigationItem.route === 'string') {
      this.$router.push(navigationItem.route)
    } else if (typeof navigationItem.route === 'function') {
      navigationItem.route()
    }
  }
}
